<template>
  <v-card>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        v-if="
          selected.length > 0 &&
          dependency_id &&
          status_filter &&
          status_filter.is_finished == false
        "
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="deleteMultipleItems"
        >{{ $t('operations.delete') }} ({{ selected.length }})
      </v-btn>
      <v-btn
        v-if="
          dependency_id && status_filter && status_filter.is_finished == false
        "
        color="teal dark"
        dark
        class="mx-1"
        @click="newItem"
      >
        {{ $t('operations.new') }}
      </v-btn>
    </v-toolbar>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="3" md="4">
          <v-select
            @change="getItems()"
            v-model="dependency_id"
            :items="dependencies"
            item-text="name"
            item-value="id"
          >
            <template v-slot:label>
              {{ $t('fields.dependency') }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" md="2">
          <v-select
            @change="getItems()"
            v-model="status_filter"
            :items="status"
            item-text="name"
            return-object
          >
            <template v-slot:label>
              {{ $t('fields.status') }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" md="2">
          <v-text-field
            v-if="status_filter && status_filter.is_finished == true"
            @change="getItems()"
            v-model="year"
            type="number"
          >
            <template v-slot:label>
              {{ $t('fields.year') }}
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            autocomplete="off"
            dense
          >
            <template v-slot:label>{{ $t('operations.search') }} </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-alert type="info" border="left" v-if="!dependency_id || !status_filter">
      {{ $t('message.select_filter_to_continue') }}
    </v-alert>
    <!-- table -->
    <v-data-table
      v-if="dependency_id && status_filter"
      v-model="selected"
      :headers="headers"
      :items="items"
      :single-select="false"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: $t('operations.items_per_page')
      }"
      sort-by="id"
      show-select
    >
      <template v-slot:loading>{{ $t('operations.loading') }} </template>
      <template v-slot:[`item.options`]="{ item }">
        <v-layout justify-end>
          <v-btn
            color="blue-grey lighten-2 dark"
            class="ml-1"
            dark
            small
            @click="editItem(item)"
            v-if="status_filter && status_filter.is_finished == false"
            >{{ $t('operations.edit') }}
          </v-btn>
          <v-btn
            color="blue-grey datken-2 dark"
            class="ml-1"
            dark
            small
            @click="finalizeItem(item)"
            v-if="status_filter && status_filter.is_finished == false"
            >{{ $t('operations.finalize') }}
          </v-btn>
          <v-btn
            color="blue-grey datken-2 dark"
            class="ml-1"
            dark
            small
            @click="itemReport(item)"
            >{{ $t('operations.export') }}
          </v-btn>
        </v-layout>
      </template>
    </v-data-table>
    <!-- finalize form -->
    <AppDialogForm ref="dialogFinalizeItem" :isValid="finalizeIsValid">
      <v-form ref="finalizeForm" lazy-validation v-model="finalizeIsValid">
        <v-row class="pt-5" v-if="is_training">
          <v-col cols="12">
            <v-alert type="error" border="left" v-if="voted <= 0">
              {{ $t('message.no_citizens_voted') }}
            </v-alert>
          </v-col>
          <v-col cols="6">
            {{ $t('cards.enrolled') }}
          </v-col>
          <v-col cols="6" align="right">
            {{ enrolled }}
          </v-col>
          <v-col cols="6">
            {{ $t('cards.voted') }}
          </v-col>
          <v-col cols="6" align="right">
            {{ voted }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-divider></v-divider>
            <v-select
              v-model="attention_status_id"
              :items="
                status.filter(function (i) {
                  return i.is_finished == true
                })
              "
              item-text="name"
              item-value="id"
              :rules="formRules"
              required
            >
              <template v-slot:label>
                {{ $t('fields.status') }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <AppDialogItem ref="dialogItem" :status="status" :url="url" />
    <!--<AppDialogCapture ref="dialogCapture" />-->
    <AppDialogConfirm ref="confirm" />
  </v-card>
</template>

<script>
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
import AppDialogForm from '@/components/dialogs/Form.vue'
import AppDialogItem from './Process'
//import AppDialogCapture from './Capture'

export default {
  name: 'AttentionProcesses',
  components: {
    AppDialogConfirm,
    AppDialogForm,
    AppDialogItem
    //AppDialogCapture
  },
  data: () => ({
    name: null,
    url: null,
    url_form: null,
    headers: [],
    formRules: [(v) => !!v || 'Required'],
    items: [],
    dependencies: [],
    status: [],
    attention_status_id: null,
    status_filter: null,
    dependency_id: null,
    enrolled: null,
    voted: null,
    is_training: null,
    selected: [],
    loading: false,
    loading_text: null,
    search: null,
    isValid: false,
    finalizeIsValid: false,
    isNewItem: null,
    year: new Date().getFullYear()
  }),

  created() {
    this.url = 'citizens_attention/processes/'
    this.url_dependencies = 'user/dependencies/'
    this.url_status = 'citizens_attention/status/'

    this.name = this.$t('options.citizens_attention.processes')
    this.headers = [
      { text: 'Id', value: 'id' },
      { text: this.$t('fields.name'), value: 'name' },
      {
        text: this.$t('fields.start_date'),
        value: 'create_date'
      },
      {
        text: this.$t('fields.final_date'),
        value: 'finish_date'
      },
      {
        text: this.$t('fields.total'),
        value: 'total'
      },
      { text: this.$t('fields.description'), value: 'description' },
      {
        text: this.$t('fields.options'),
        value: 'options',
        align: 'right'
      }
    ]

    this.getDepencencies()
    this.getStatus()
  },

  methods: {
    getItems() {
      this.loading = true
      this.items = []
      this.selected = []
      if (this.dependency_id && this.status_filter) {
        let url = `${this.url}?attention_status=${this.status_filter.id}`
        url += `&dependency_id=${this.dependency_id}`
        if (this.status_filter.is_finished == true) {
          url += `&create_date__gte=${this.year}-01-01`
          url += `&create_date__lte=${this.year}-12-31`
        }
        this.$http.get(url).then((request) => {
          this.loading = false
          this.items = request.data
        })
      }
    },

    newItem() {
      let item = {
        dependency_id: this.dependency_id,
        attention_status_id: this.status_filter.id
      }
      this.$refs.dialogItem.newItem(item).then(() => {
        setTimeout(() => {
          this.getItems()
        }, 1000)
      })
    },

    editItem(item) {
      this.$refs.dialogItem.editItem(item).then(() => {
        this.getItems()
      })
    },

    deleteItem(item) {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, message, options).then((confirm) => {
        if (confirm)
          this.$http
            .delete(`${this.url}?id=${item.id}`)
            .then(() => this.getItems())
      })
    },

    async deleteMultipleItems() {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      let confirm = await this.$refs.confirm.open(title, message, options)
      if (await confirm) {
        var processed = 0
        this.selected.forEach(async (item) => {
          await this.$http.delete(`${this.url}?id=${item.id}`).then(() => {
            processed++
            if (processed === this.selected.length) {
              this.getItems()
            }
          })
        })
      }
    },

    finalizeItem(item) {
      let options = { color: 'teal' }
      let title = `${this.$t('operations.finalize').toUpperCase()} [${item.id}]`
      this.attention_status_id = null
      this.enrolled = item.enrolled
      this.voted = item.voted
      this.$refs.dialogFinalizeItem.open(title, options).then((confirm) => {
        if (
          confirm &&
          this.$refs.finalizeForm.validate() &&
          this.attention_status_id
        ) {
          let data = {
            is_finished: this.status.find(
              (x) => x.id == this.attention_status_id
            ).is_finished,
            show_in_reports: this.status.find(
              (x) => x.id == this.attention_status_id
            ).show_in_reports,
            attention_status_id: this.attention_status_id
          }
          this.$http
            .put(`${this.url}?id=${item.id}`, JSON.stringify(data))
            .then(() => this.getItems())
        }
      })
    },

    getStatus() {
      this.$http.get(this.url_status).then((request) => {
        this.status = request.data
      })
    },

    getDepencencies() {
      this.$http.get(this.url_dependencies).then((request) => {
        this.dependencies = request.data
      })
    },

    itemCitizens(item) {
      this.$refs.dialogCitizens.open(item.id).then(() => this.getItems())
    },

    itemReport(item) {
      let url = `${this.url}file/?attention_process=${item.id}`
      this.$http.get(url, { responseType: 'blob' }).then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${item.name}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      })
    }
  }
}
</script>

<style></style>
