<template>
  <v-card>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="3" md="4">
          <v-select
            @change="
              getProcesses()
              startCapture()
            "
            v-model="dependency_id"
            :items="dependencies"
            item-text="name"
            item-value="id"
          >
            <template v-slot:label>
              {{ $t('fields.dependency') }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" md="4">
          <v-select
            @change="startCapture()"
            v-model="attention_process_id"
            :items="processes"
            item-text="name"
            item-value="id"
          >
            <template v-slot:label>
              {{ $t('fields.process') }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" md="4">
          <v-select
            @change="startCapture()"
            v-model="identification_type_id"
            :items="identification_types"
            item-text="name"
            item-value="id"
            :rules="formRules"
          >
            <template v-slot:label>
              {{ $t('fields.identification_type') }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-alert
      type="info"
      border="left"
      v-if="!dependency_id || !attention_process_id || !identification_type_id"
    >
      {{ $t('message.select_filter_to_continue') }}
    </v-alert>

    <v-card-text
      v-if="dependency_id && attention_process_id && identification_type_id"
    >
      <v-form ref="form" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="6" sm="6" md="6">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  id="identification_number"
                  v-model="item.identification_number"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.identification_number') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="item.last_name_1"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.last_name') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="item.last_name_2" autocomplete="off">
                  <template v-slot:label>
                    {{ $t('fields.last_name') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="item.first_name_1"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.first_name') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="item.first_name_2" autocomplete="off">
                  <template v-slot:label>
                    {{ $t('fields.first_name') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.sex"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.sex') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.birthday"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.birthday') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="item.blood_type"
                  autocomplete="off"
                  :rules="formRules"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.RH') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-btn
                  color="teal dark"
                  dark
                  class="mx-1"
                  block
                  @click="save"
                  v-on:keydown.enter="$event.stopPropagation()"
                >
                  {{ $t('operations.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-alert
              v-for="(result, index) in results"
              v-bind:key="index"
              text
              :color="result.is_valid ? 'teal' : 'red'"
              :icon="result.is_valid ? 'mdi-check' : 'mdi-close'"
              border="left"
              promiment
            >
              <v-layout>
                {{ result.first_name }} {{ result.last_name }}
                <v-spacer></v-spacer>
                {{ result.time }}
              </v-layout>
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <!-- alert form -->
    <AppDialogForm ref="dialogFinalizeItem"> </AppDialogForm>
  </v-card>
</template>

<script>
import AppDialogForm from '@/components/dialogs/Form.vue'

export default {
  name: 'AttentionProcesses',
  components: {
    AppDialogForm
  },
  data: () => ({
    name: null,
    headers: [],
    formRules: [(v) => !!v || 'Required'],
    processes: [],
    dependencies: [],
    identification_types: [],
    status: [],
    attention_process_id: null,
    dependency_id: null,
    identification_type_id: null,
    isValid: false,
    item: {},
    results: [],
    context: new AudioContext()
  }),

  created() {
    this.url = 'citizens_attention/citizens/'
    this.url_processes = 'citizens_attention/processes/'
    this.url_dependencies = 'user/dependencies/'
    this.url_identification_types = 'system/identification_types/'

    this.name = this.$t('options.citizens_attention.capture')

    this.getDepencencies()
    this.getIdentificationTypes()
  },

  methods: {
    beep(frequency, type) {
      const o = this.context.createOscillator()
      const g = this.context.createGain()
      o.type = type
      o.connect(g)
      o.frequency.setValueAtTime(frequency, 0)
      g.connect(this.context.destination)
      o.start(0)
      g.gain.exponentialRampToValueAtTime(0.0001, this.context.currentTime + 1)
      setTimeout(function () {
        o.stop(0)
      }, 3000)
    },

    getIdentificationTypes() {
      this.$http.get(this.url_identification_types).then((request) => {
        this.identification_types = request.data
      })
    },

    getDepencencies() {
      this.$http.get(this.url_dependencies).then((request) => {
        this.dependencies = request.data
      })
    },

    getProcesses() {
      this.processes = []
      this.attention_process_id = null
      if (this.dependency_id) {
        let url = `${this.url_processes}?is_finished=0`
        url += `&dependency_id=${this.dependency_id}`
        this.$http.get(url).then((request) => {
          this.processes = request.data
        })
      }
    },

    startCapture() {
      if (
        this.dependency_id &&
        this.identification_type_id &&
        this.attention_process_id
      ) {
        this.item = {}
        setTimeout(() => {
          this.$refs.form.reset()
          document.getElementById('identification_number').focus()
        }, 100)
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.item.sex === '0') {
          this.item.sex = this.item.birthday.slice(0, 1)
          this.item.birthday = [
            this.item.birthday.slice(1),
            this.item.blood_type.slice(0, 1)
          ].join('')
          this.item.blood_type = this.item.blood_type.slice(1)
        }
        this.item.first_name_1 =
          this.item.first_name_1 === null ? '' : this.item.first_name_1.trim()
        this.item.first_name_2 =
          this.item.first_name_2 === null ? '' : this.item.first_name_2.trim()
        this.item.last_name_1 =
          this.item.last_name_1 === null ? '' : this.item.last_name_1.trim()
        this.item.last_name_2 =
          this.item.last_name_2 === null ? '' : this.item.last_name_2.trim()
        let item = {
          attention_process_id: this.attention_process_id,
          identification_type_id: this.identification_type_id,
          identification_number: parseInt(this.item.identification_number),
          first_name: `${this.item.first_name_1} ${this.item.first_name_2}`,
          last_name: `${this.item.last_name_1} ${this.item.last_name_2}`,
          sex: this.item.sex,
          bood_type: this.item.blood_type,
          birthday: [
            this.item.birthday.slice(0, 4),
            this.item.birthday.slice(4, 6),
            this.item.birthday.slice(6)
          ].join('-')
        }

        this.$http.post(this.url, JSON.stringify(item)).then((request) => {
          let result = request.data
          result.time = this.formatAMPM(new Date())
          if (!result.is_valid) {
            this.beep(659.3, 'square')
          }
          this.results.unshift(result)
          if (this.results.length > 5) {
            this.results.pop()
          }
          this.startCapture()
        })
      }
    },

    formatAMPM(date) {
      var hours = date.getHours()
      var minutes = date.getMinutes()
      var ampm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      var strTime = `${hours}:${minutes} ${ampm}`
      return strTime
    }
  }
}
</script>

<style></style>
