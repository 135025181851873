<template>
  <AppDialogItem ref="dialogItem" :isValid="isValid">
    <v-card-text class="ma-0">
      <v-form ref="form" lazy-validation v-model="isValid" width="100px">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="item.name"
              autocomplete="off"
              :rules="formRules"
              required
            >
              <template v-slot:label>
                {{ $t('fields.name') }}
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea counter rows="2" v-model="item.description">
              <template v-slot:label>
                {{ $t('fields.description') }}
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </AppDialogItem>
</template>

<script>
import AppDialogItem from '@/components/dialogs/Form.vue'
export default {
  name: 'Votings',
  components: {
    AppDialogItem
  },
  props: ['url', 'status'],
  data: () => ({
    formRules: [(v) => !!v || 'Required'],
    item: {},
    isValid: false
  }),

  created() {},

  methods: {
    newItem(item) {
      let options = {
        color: 'teal',
        save: this.$t('operations.save')
      }
      let title = this.$t('operations.new').toUpperCase()
      this.item = Object.assign({}, item)
      this.isNewItem = true
      return this.$refs.dialogItem.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate()) {
          this.$http.post(this.url, JSON.stringify(this.item))
        }
      })
    },

    editItem(item) {
      let options = {
        color: 'teal',
        save: this.$t('operations.save')
      }
      let title = `${this.$t('operations.editing').toUpperCase()} [${item.id}]`
      this.item = Object.assign(
        {},
        {
          id: item.id,
          name: item.name,
          description: item.description,
          attention_status_id: item.attention_status_id
        }
      )
      return this.$refs.dialogItem.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate())
          this.$http.put(
            `${this.url}?id=${this.item.id}`,
            JSON.stringify(this.item)
          )
      })
    },

    uploadFile() {
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      this.showImage = false
      this.image_file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        this.image = reader.result
        this.showImage = true
      }
      this.$refs.uploader.value = null
    },

    copyText() {
      var node = document.getElementById('url_form')
      if (document.body.createTextRange) {
        const range = document.body.createTextRange()
        range.moveToElementText(node)
        range.select()
      } else if (window.getSelection) {
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(node)
        selection.removeAllRanges()
        selection.addRange(range)
      }

      document.execCommand('copy')

      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
    },

    openForm() {
      window.open(this.url_form)
    }
  }
}
</script>

<style>
</style>